<template>
  <el-row class="table">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="备课列表" name="first">
        <prepareLesson  ref="prepareLesson"></prepareLesson>
      </el-tab-pane>
      <el-tab-pane label="授课列表" name="second">
        <myLessonList  ref="myLessonList"></myLessonList>
        <!-- <teachClass  ref="teachnowClass"></teachClass> -->
      </el-tab-pane>
    </el-tabs>
  </el-row>
  <!-- 
    courseUnitId=8018ecc4-5542-495a-9a56-bda2914eee94
  &courseTitle=我的世界-躲避怪物
  &lessonId=e6845e8f-6c72-4bc6-8893-7f89d60c8ecf
  &classId=817a8c63-eceb-4711-bac1-f25defb2ea76
  &courseStatus=已上课 -->
</template>

<script>
  import myLessonList from '@/viewsteacher/components/try-lesson/myLessonList'
  import prepareLesson from '@/viewsteacher/components/try-lesson/prepareLesson'
  export default {
    data() {
      return {
        activeName: 'first',
      };
    },
    components: {
      myLessonList,
      prepareLesson
    },
    methods:{
      handleClick(tab, event) {
        if(this.activeName === 'first' ) {
          // this.$refs.teachnowClass.getTeacherLessonList()
        }else if(this.activeName === 'second') {
         this.$nextTick(()=> {
          //  this.$refs.myLessonList.getClassByTeacher()
         })
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/style/table.scss';
</style>
