<template>
  <!-- <div style="margin: 12px 24px"> -->
  <div>
    <el-form :inline="true">
      <div class="row-bg">
        <el-row :gutter="12">
          <el-col :xl="8" :lg="8" :md="24">
            <el-form-item label="日期区间：">
              <date-picker ref="dataPicker" @updateView="datetTimeGet" class="w100"></date-picker>
            </el-form-item>
          </el-col>
          <template>
            <el-col :xl="8" :lg="8" :md="24">
              <el-form-item label="上课状态：">
                <el-select
                  v-model="queryParam.lessonState"
                  class="w100"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in lessonStateArray"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </template>
          <search-query
            @updateView="updateView"
            @clearQuery="clearQuery"
            @searchQuery="search"
            :showMore=false
          ></search-query>
        </el-row>
      </div>
    </el-form>
    <!-- dataList: {{dataList}} -->
    <!-- 表格 -->
    <el-card shadow="never">
      <div slot="header" class="flex">
        <span>排课数据</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :key="tableKey"
          :data="dataList"
          fit
          @sort-change="tableSortAll"
          :default-sort="{ prop: 'start_time', order: 'ascending' }"
          style="margin-top: 15px;"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '上课时间'">
                <div>
                  <span>（{{scope.row.start_time.substring(0, 10) | weekDay}}）</span>
                  <span>{{ scope.row.start_time | formatDateStart }}</span>
                  <span>{{ scope.row.end_time | formatDateEnd }}</span>
                </div>
              </template>
              <template v-else-if="item.label === '上课学员'">
                <el-popover
                  v-if="scope.row.lessonStudentList !== null"
                  placement="right"
                  width="300"
                  style="height: 400px;overflow-y: scroll"
                  trigger="click">
                  <el-table :data="scope.row.lessonStudentList">
                    <el-table-column width="100" property="name" label="姓名" align="center"></el-table-column>
                    <el-table-column width="160" property="loginName" label="登录名" align="center"></el-table-column>
                  </el-table>
                  <el-button slot="reference" type="text" style="text-decoration:underline">{{scope.row.lessonStudentList.length}}</el-button>
                </el-popover>
                <el-button v-else slot="reference" type="text" style="text-decoration:underline">0</el-button>
              </template>
              <template v-else-if="item.label === '课堂任务'">
                <span v-for="(item, index) in scope.row.courseUnitTitleList" :key="index">
                  <span>
                    {{item}}<span v-if="(index+1) !== scope.row.courseUnitTitleList.length">、</span>
                  </span>
                </span>
              </template>
              <template v-else-if="item.label === '上课老师'">
                <template v-if="scope.row.lessonTeacherList && scope.row.lessonTeacherList.length !== 0">
                  <el-row v-for="(item, key) in scope.row.lessonTeacherList" :key="key + 'zjName'">
                    <el-row v-if="item.type === '主教' && Boolean(item.teacher)" class="zjName mb-10">{{item.teacher.name}}</el-row>
                  </el-row>
                </template>
              </template>
              <template v-else-if="item.label === '类型'">
                {{scope.row.type}}
              </template>
              <template v-else-if="item.label === '状态'">
                <span
                  class="span-state"
                  :style="
                    'backgroundColor' + ':' + getLessonFinishState(scope.row.finished_status)
                  "
                ></span>
                <span v-text="scope.row.finished_status"></span>
              </template>
              <template v-else-if="item.label === '排课操作'">
                <el-button v-if="scope.row.courseUnitList&&scope.row.courseUnitList[0]&&scope.row.courseUnitList[0].teachOnOff=='Y'" type="text" @click="gotoGiveLesson(scope.row)">
                  去上课
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
      <el-pagination
        style="float: right;margin-right: 20px;"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-card>

  </div>
</template>

<script>
  import TextButton from '@/components/TextButton'
  import SearchQuery from '@/components/SearchQuery'
  import getStateColor from '@/mixins/getStateColor'
  import datePicker from '@/components/date-picker'
  import {getListTryLesson} from '@/api/businesscourse/course'
  import { formatDate } from '@/utils/formatDate'

  // 默认展示列
  const baseColSetting = [
    {
      label: '上课时间',
      state: true,
      align: 'center',
      sortable: true,
      prop: 'start_time',
      width: '235',
    },
    {
      label: "上课学员",
      state: true,
      align: "center",
      width: "150",
    },
    {
      label: '课堂任务',
      state: true,
      align: 'center',
      width: '150'
    },
    {
      label: '状态',
      prop: 'status',
      state: true,
      align: 'center',
      width: '100'
    },
    {
      label: '排课操作',
      state: true,
      align: 'center',
      width: '150',
    }
  ]
  export default {
    components: { TextButton, SearchQuery,datePicker },
    props: {
      type: {
        default: '',
        type: String,
        require: true,
      },
    },
    mixins: [getStateColor],
    created() {
      this.getTeacherLessonList()
    },
    computed: {
      defaultHeader() {
        return this.colSetting.filter(item => item.state)
      },
    },
    data() {
      return {
        orderBy:'l.start_time',
        addlessonData:{},
        tableKey: 1,
        // 查询参数
        queryParam: {isFinish:false},
        colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
        baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0,
        },
        lessonStateArray: [
          {
            value: '未上课',
            label: '未上课',
          },
          {
            value: '已上课',
            label: '已上课',
          },
          {
            value: '正在上课',
            label: '正在上课',
          },
        ],
        classIsAsc: false, // 默认为升序
        minStartTime: '',
        maxStartTime: '',
        dialogStu: false,
        lessonData: {},
        lessonDialog: false,
        lessonId: '',
        classId: '',
        courseId:'',
        schooltime: "",
        className: "",
        product_id: "",
        lessonStudentDialog:false,
        dataList: [],
        chooseDate: [],
        classInfo:{},
        lesson_id: '',
        class_id: '',
      }
    },
    methods: {
      datetTimeGet(data) {
        if(data === null) {
          this.minStartTime = ''
          this.maxStartTime =  ''
        }else {
          this.minStartTime = data[0]
          this.maxStartTime =  data[1]
        }

      },
      search() {
        this.pagination.currentPage = 1
        this.getTeacherLessonList()
      },

      // 获取老师试听排课
      async getTeacherLessonList () {
        const res = await getListTryLesson({
          pageNum: this.pagination.currentPage,
          pageSize:  this.pagination.pageSize,
          needCount:true,
          orderBy: this.orderBy,
          // type: '试听',
          lessonStatus:this.queryParam.lessonState,
          minStartTime: this.minStartTime ? this.minStartTime + ' 00:00:00' : '',
          maxStartTime: this.maxStartTime ? this.maxStartTime + ' 23:59:59' : ''
        })
        this.dataList  = res.body.list
        this.pagination.total = res.body.total
      },

      // 列表排序
      tableSortAll(column) {
        switch (column.order) {
          case 'ascending':
            this.orderBy = 'l.start_time'
            break
          case 'descending':
            this.orderBy = 'l.start_time desc'
        }
        this.getTeacherLessonList()
      },

      updateView(data) {

      },

      // 清空选项
      clearQuery() {
        this.queryParam = {}
        this.$refs.dataPicker.time = []
        this.maxStartTime = ''
        this.minStartTime = ''
        this.staffList = []
      },

      update(data) {
        this.tableKey = this.tableKey + 1
        if (data.lenght !== 0) {
          this.colSetting.forEach(item => {
            item.state = false
            data.forEach(ele => {
              if (ele === item.label && !item.state) {
                item.state = true
              }
            })
          })
        } else {
          this.colSetting = []
        }
      },

      // 分页
      handleCurrentChange (val) {
        this.pagination.currentPage = val
        this.getTeacherLessonList()
      },
      handleSizeChange (val) {
        this.pagination.pageSize = val
        this.getTeacherLessonList()
      },

      // 设置标题
      setTitle (ele) {
        const start = formatDate(new Date(ele.start_time), 'hh:mm')
        const end = formatDate(new Date(ele.end_time), 'hh:mm')
        let setTitle = ""
        if(ele.classInfo !== null) {
          setTitle = `${start}-${end} ${ele.classInfo.name}`
        }else {
          setTitle = `${start}-${end}`+"试听课"
        }
        return setTitle
      },

      // 去上课
      gotoGiveLesson (row) {
        let routeData = this.$router.resolve({
          path: '/teacher/giveLesson-detail',
          query: {
            courseUnitId: row.courseUnitIdList[0],
            courseTitle: row.courseUnitList[0].title,
            lessonId: row.id,
            classId: row.class_id,
          },
        })
        window.open(routeData.href, '_blank')
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/style/container.scss";
</style>
